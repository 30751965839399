/* Privacy Policy Page  */

.p3 {
  -webkit-text-decoration-skip: none;
  color: #65b4b4;
  font-weight: 400;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 12pt;
  font-family: 'Times New Roman';
  font-style: normal;
}

.p1 {
  background-color: #fefefe;
  padding-top: 5pt;
  padding-bottom: 8pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: center;
}

.p5 {
  color: #212529;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: 'Times New Roman';
  font-style: normal;
}

.p10 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Calibri';
  font-style: normal;
}

.p0 {
  color: #212529;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: 'Times New Roman';
  font-style: normal;
}

.p6 {
  -webkit-text-decoration-skip: none;
  color: #65b4b4;
  font-weight: 400;
  text-decoration: underline;
  text-decoration-skip-ink: none;
  font-size: 13pt;
  font-family: 'Calibri';
}

.p2 {
  background-color: #fefefe;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.p11 {
  font-size: 13pt;
  font-family: 'Calibri';
  color: #954f72;
  font-weight: 400;
  margin: 0 5px;
}

.p12 {
  font-size: 13pt;
  font-family: 'Calibri';
  color: #65b4b4;
  font-weight: 400;
}

.p4 {
  font-size: 13pt;
  font-family: 'Calibri';
  color: #f2318d;
  font-weight: 400;
}

.p8 {
  font-size: 12pt;
  font-family: 'Times New Roman';
  color: #212529;
  font-weight: 400;
}

.p9 {
  background-color: #ffffff;
  max-width: 100%;
  padding: 20px 70px;
}

@media screen and (max-width: 600px) {
  .p9 {
    padding: 20px;
  }
}

.p13 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  text-decoration-skip-ink: none;
}

/* Term of use Page */

.t0 {
  font-size: 7pt;
  font-family: 'Times New Roman';
  font-weight: 400;
}

.t1 {
  margin-left: 36pt;
  line-height: 1.0700000249422514;
  orphans: 2;
  widows: 2;
  text-align: justify;
  height: 11pt;
}
.t2 {
  font-size: 14pt;
  font-family: 'Times New Roman';
  font-weight: 400;
}

.t5 {
  margin-left: 25pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.t9 {
  background-color: #ffffff;
  padding-top: 6pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.t4 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
  height: 11pt;
}

.t7 {
  background-color: #ffffff;
  padding-top: 5pt;
  padding-bottom: 5pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.t26 {
  padding-top: 0pt;
  text-indent: 18pt;
  padding-bottom: 8pt;
  line-height: 1.070000019940463;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.t15 {
  margin-left: -7pt;
  padding-top: 13pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.t16 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.070000019940463;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.t3 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 13pt;
  font-family: 'Times New Roman';
  font-style: normal;
}

.t6 {
  margin-left: 36pt;
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.0700000249422514;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.t10 {
  padding-top: 0pt;
  text-indent: 18pt;
  padding-bottom: 8pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.t22 {
  margin-left: 18pt;
  padding-bottom: 8pt;
  line-height: 1.070000019940463;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.t28 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.t24 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.t17 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.070000019940463;
  text-align: center;
}

.t23 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.070000019940463;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.c12 {
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.t30 {
  padding-top: 0pt;
  padding-bottom: 8pt;
  line-height: 1.070000019940463;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.t13 {
  padding-bottom: 8pt;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.t32 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}

.t14 {
  color: #000000;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}

.t34 {
  font-size: 10.5pt;
  font-family: 'Verdana';
  font-weight: 400;
}

.t20 {
  font-weight: 400;
  font-size: 15pt;
  font-family: 'Times New Roman';
}

.c19 {
  font-weight: 400;
  font-size: 11pt;
  font-family: 'Arial';
}

.t11 {
  font-size: 13pt;
  font-family: 'Times New Roman';
  font-weight: 400;
}

.t8 {
  background-color: #ffffff;
  max-width: 100%;
  padding: 20px 70px;
}

@media screen and (max-width: 600px) {
  .t8 {
    padding: 20px;
  }
}

.t27 {
  margin-left: 25pt;
  margin-bottom: 15pt;
}

.t21 {
  margin-left: 20pt;
}

.t25 {
  margin-left: 54pt;
  text-indent: -18pt;
}

.t31 {
  height: 11pt;
}

.t33 {
  text-indent: 35pt;
}

.t29 {
  margin-left: -7pt;
}

.t35 {
  font-size: 13pt;
}

.t18 {
  margin-left: 16pt;
}

p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: 'Arial';
}

/* Offer page */

.list li {
  padding-left: 10px;
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Arial';
  font-style: normal;
  padding-bottom: 15px;
}

.c0 {
  padding-top: 0pt;
  padding-bottom: 10pt;
  line-height: 1.1500000086697666;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c1 {
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: justify;
}

.c2 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: 'Arial';
  font-style: normal;
}
.c3 {
  text-indent: 35pt;
}

.c4 {
  padding-top: 0pt;
  padding-bottom: 10pt;
  line-height: 1.1500000086697666;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c5 {
  height: 11pt;
}

.c7 {
  background-color: #ffffff;
  max-width: 100%;
  padding: 20px 70px;
}
@media screen and (max-width: 600px) {
  .c7 {
    padding: 20px;
  }
}
