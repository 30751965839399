.react-pdf__Page__canvas {
  max-width: 100%;
  height: auto !important;
}

.react-pdf__Page__textContent {
  width: 100vw !important;
  max-width: 100vw;
  overflow: hidden;
}
