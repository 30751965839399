@tailwind base;
@tailwind components;
@tailwind utilities;
/* 
@font-face {
  font-family: 'Roboto';
  src: url('../Assets/fonts/Roboto/Roboto-Regular.ttf');
}
@font-face {
  font-family: 'Roboto-Medium';
  src: url('../Assets/fonts/Roboto/Roboto-Medium.ttf');
}
@font-face {
  font-family: 'Roboto-Bold';
  src: url('../Assets/fonts/Roboto/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Inter';
  src: url('../Assets/fonts/Inter/Inter-Regular.ttf');
}

@font-face {
  font-family: 'Inter';
  src: url('../Assets/fonts/Inter/Inter-Medium.ttf');
}

@font-face {
  font-family: 'Inter';
  src: url('../Assets/fonts/Inter/Inter-Bold.ttf');
} */

*:focus {
  outline: none;
}

body {
  @apply text-smena_text font-inter;
}

@layer components {
  /* Text styles */
  .H1 {
    @apply font-normal text-5xl leading-[56px];
  }
  .H2 {
    @apply font-normal text-[36px] leading-[42px] tracking-[0.25px];
  }
  .H3 {
    @apply font-normal text-[24px] leading-[32px];
  }
  .H4 {
    @apply font-medium text-[20px] leading-[32px] tracking-[0.15px];
  }
  .Subtitle1 {
    @apply font-medium text-[16px] leading-[28px] tracking-[0.15px];
  }
  .Subtitle2 {
    @apply font-medium text-[14px] leading-[22px] tracking-[0.1px];
  }
  .Body1 {
    @apply font-normal text-[15px] leading-[24px] tracking-[0.15px];
  }
  .Body2 {
    @apply font-normal text-[13px] leading-[20px] tracking-[0.15px];
  }
  .Table {
    @apply font-roboto font-normal text-[14px] leading-[20px] tracking-[0.15px];
  }
  .Table-small {
    @apply font-roboto font-normal text-[13px] leading-[20px] tracking-[0.15px];
  }
  .Table-small-bold {
    @apply font-roboto font-bold text-[13px] leading-[20px] tracking-[0.15px];
  }
  .Table-H1 {
    @apply font-roboto font-medium text-[14px] leading-[20px] tracking-[0.65px];
  }
  .Button1 {
    @apply font-bold text-[14px] leading-[24px];
  }
  .Button2 {
    @apply font-bold text-[12px] leading-[24px];
  }
  .Button3 {
    @apply font-bold text-[10px] leading-[16px];
  }
  .Caption {
    @apply font-normal text-[12px] leading-[20px] tracking-[0.4px];
  }
  .Caption-numbers {
    @apply font-roboto font-medium text-[12px] leading-[24px] tracking-[0.1px];
  }
  .Caption-small {
    @apply font-normal text-[10px] leading-[17px] tracking-[0.4px];
  }
  .Overline {
    @apply font-normal text-[12px] leading-[32px] tracking-[1px] uppercase;
  }
  .Tag {
    @apply font-roboto font-medium text-[10px] leading-[17px] tracking-[0.4px];
  }
  .ChartNumber {
    @apply font-bold text-[24px] leading-[32px];
  }
  .ChartBigNumber {
    @apply font-bold text-[40px] leading-[53px];
  }
  .btn-split {
    @apply bg-primary text-white font-inter normal-case flex items-center justify-center
    cursor-pointer overflow-hidden relative z-10 border-smena_white border-solid
    duration-[0.2s] font-bold text-[14px] leading-[24px]
    disabled:opacity-50 disabled:cursor-not-allowed !important;
  }
  .btn-split__left {
    @apply rounded-l-lg border pt-1 px-6 !important;
  }

  .btn-split__right {
    @apply rounded-r-lg border-l-2 pt-1 px-2 !important;
  }

  .dot-legend {
    @apply rounded-full w-[14px] h-[14px];
  }
  .smena-sidebar .ant-picker-dropdown{
    @apply fixed
  }
  .table__row:nth-child(even) {
    @apply bg-smena_gray-2;
  }

  .table__row:nth-child(odd) {
    @apply bg-smena_white;
  }
}

[type='radio']:disabled {
  @apply cursor-not-allowed;
}

.btn-primary_big,
.btn-primary,
.btn-primary_small,
.btn-primary_extra_small,
.btn-secondary_big,
.btn-secondary,
.btn-secondary_small,
.btn-secondary_extra_small,
.btn-reject_big,
.btn-reject,
.btn-reject_small,
.btn-reject_extra_small,
.btn-reject_secondary_big,
.btn-reject_secondary,
.btn-reject_secondary_small,
.btn-reject_secondary_extra_small,
.btn-stroke_reject_big,
.btn-stroke_reject,
.btn-stroke_reject_small,
.btn-stroke_reject_extra_small,
.btn-stroke_big,
.btn-stroke,
.btn-stroke_small,
.btn-stroke_extra_small {
  @apply font-inter rounded-lg cursor-pointer overflow-hidden
  relative z-10 transition font-bold leading-[24px]
  disabled:opacity-50 disabled:cursor-not-allowed;
}

.btn-reject_secondary_big,
.btn-reject_secondary,
.btn-reject_secondary_small,
.btn-reject_secondary_extra_small {
  @apply bg-smena_red-extra_light text-smena_red hover:bg-smena_red-light active:bg-smena_red-light;
}

.btn-reject_big,
.btn-reject,
.btn-reject_small,
.btn-reject_extra_small {
  @apply bg-smena_red text-white hover:bg-smena_red-light active:bg-smena_red-dark;
}

.btn-primary_big,
.btn-primary,
.btn-primary_small,
.btn-primary_extra_small {
  @apply bg-primary text-white hover:bg-primary-secondary active:bg-primary-dark;
}

.btn-primary_big,
.btn-reject_big,
.btn-reject_secondary_big,
.btn-stroke_reject_big,
.btn-secondary_big,
.btn-stroke_big {
  @apply text-[14px] px-9 py-1 h-8;
}

.btn-primary,
.btn-reject,
.btn-reject_secondary,
.btn-stroke_reject,
.btn-secondary,
.btn-stroke {
  @apply text-[14px] py-1 px-6 h-8;
}

.btn-primary_small,
.btn-reject_small,
.btn-reject_secondary_small,
.btn-stroke_reject_small,
.btn-secondary_small,
.btn-stroke_small {
  @apply text-[12px] py-1 px-3 h-8;
}

.btn-primary_extra_small,
.btn-reject_extra_small,
.btn-reject_secondary_extra_small,
.btn-secondary_extra_small,
.btn-stroke_reject_extra_small,
.btn-stroke_extra_small {
  @apply text-[10px] px-[6px] h-6;
}

.nav-link,
.nav-link_active,
.nav-link_margin,
.nav-link_active_margin,
.sub_nav-link,
.sub_nav-link_active {
  @apply flex px-3 py-2 rounded-lg truncate font-medium text-[14px] leading-[22px] tracking-[0.1px];
}

.nav-link,
.nav-link_margin,
.nav-link_hidden,
.nav-link_margin_hidden,
.sub_nav-link {
  @apply hover:bg-primary-light hover:text-smena_white;
}

.nav-link_active,
.nav-link_active_margin,
.nav-link_active_hidden,
.nav-link_active_margin_hidden,
.sub_nav-link_active {
  @apply bg-primary text-smena_white;
}

.nav-link_margin,
.nav-link_active_margin,
.nav-link_margin_hidden,
.nav-link_active_margin_hidden {
  @apply mb-10;
}
.nav-link_hidden,
.nav-link_active_hidden,
.nav-link_margin_hidden,
.nav-link_active_margin_hidden {
  @apply flex p-2 rounded-full text-xl w-max;
}

.sub_nav-link,
.sub_nav-link_active {
  @apply ml-[10px];
}

.btn-lodaing {
  @apply text-white text-sm py-2 px-14 rounded focus:outline-none cursor-pointer;
}

.btn-secondary_big,
.btn-secondary,
.btn-secondary_small,
.btn-secondary_extra_small {
  @apply bg-smena_bb-background text-primary hover:bg-smena_bb-border_light active:bg-smena_bb-border;
}

.btn-stroke_big,
.btn-stroke,
.btn-stroke_small,
.btn-stroke_extra_small {
  @apply bg-white text-primary-dark hover:bg-smena_bb-background active:bg-smena_bb-border;
  border: 1px solid rgba(27, 96, 227, 0.3);
}

.btn-stroke_reject_big,
.btn-stroke_reject,
.btn-stroke_reject_small,
.btn-stroke_reject_extra_small {
  @apply bg-smena_white text-smena_red hover:bg-smena_red-extra_light active:bg-smena_red-light;
  border: 1px solid rgba(255, 85, 95, 0.3);
}

.label-primary {
  @apply block font-inter truncate;
  font-weight: 500;
  font-size: 14px;
  line-height: 157%;
  letter-spacing: 0.1px;
}

.label-checkbox {
  @apply font-inter block;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.15px;
}

.textarea-primary,
.textarea-error,
.input-primary,
.input-error {
  @apply block w-full text-sm border rounded-lg
  disabled:bg-smena_gray-5 disabled:cursor-not-allowed;
  height: 32px;
  padding-top: 6px;
  padding-bottom: 6px;
  letter-spacing: 0.15px;
}

.textarea-primary {
  @apply border-smena_gray-40;
}

.documents-textarea {
  height: 50px;
}

.input-primary {
  @apply border-smena_gray-40;
}

.radio-primary {
  @apply p-3;
}

.radio-primary:has([type='radio']:checked) {
  background: red;
}
.input-error {
  @apply border-smena_red-mark focus:ring-0 focus:border-smena_red focus:border-1.5;
}
.textarea-error {
  @apply border-smena_red-mark focus:ring-0 focus:border-smena_red focus:border-1.5;
}

.input-primary:enabled::placeholder,
.textarea-primary::placeholder {
  @apply text-smena_text-helper;
}

.multySelect {
  @apply w-full;
}

.MuiFormControl-root {
  height: 32px !important;
}

.multySelect .MuiSelect-multiple,
.Mui-error .MuiSelect-multiple,
.select-primary,
.select-error,
.single-select {
  @apply block w-full text-smena_text border border-solid text-sm bg-white rounded-lg
  disabled:cursor-not-allowed disabled:bg-smena_gray-5
  hover:border-primary focus:ring-0 focus:border-primary focus:border-1.5 !important;
  height: 32px !important;
  padding-left: 12px;
  padding-top: 6px;
  padding-bottom: 6px;
  letter-spacing: 0.15px;
}

.multySelect .MuiSelect-multiple {
  @apply border-smena_gray-40 shadow-sm
  disabled:cursor-not-allowed disabled:bg-smena_gray-5
  hover:border-primary focus:ring-0 focus:border-primary focus:border-1.5 !important;
}

.Mui-error .MuiSelect-multiple {
  @apply border-smena_red-mark shadow-sm
  focus:ring-0 focus:border-smena_red focus:border-1.5
  disabled:cursor-not-allowed !important;
}

.select-primary,
.select-error,
.single-select {
  @apply disabled:cursor-not-allowed;
}

.select-primary {
  @apply border-smena_gray-40
  disabled:bg-smena_gray-5;
}

.select-error {
  @apply border-smena_red-mark
  focus:ring-0 focus:border-smena_red focus:border-1.5;
}

.input-primary:enabled,
.select-primary:enabled {
  @apply hover:border-primary focus:ring-0 focus:border-primary focus:border-1.5;
}

.checkbox-primary {
  @apply block rounded-md mr-3 text-primary border-transparent bg-smena_bb-background
  disabled:opacity-50 disabled:text-smena_gray-50 disabled:cursor-not-allowed;
  width: 18px;
  height: 18px;
}

label.MuiInputLabel-animated {
  transform: translate(14px, 9px) scale(1);
  color: #747a87;
  font-size: 0.875rem;
}

label.MuiInputLabel-animated.Mui-focused {
  transform: translate(14px, -9px) scale(0.75);
}

label.MuiFormLabel-filled {
  transform: translate(14px, -9px) scale(0.75);
}

/* Styling custom select  */
.MuiMenuItem-root {
  @apply font-inter !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  line-height: 143% !important;
  letter-spacing: 0.15px !important;
}

.MuiMenuItem-root.Mui-selected {
  width: 100%;
  justify-content: space-between !important;
  background-color: #f5f8ff !important;
}

.MuiMenuItem-root.Mui-selected::after {
  content: '';
  background-image: url('../images/check.svg') !important;
  height: 24px;
  width: 24px;
}

.MuiMenu-list .MuiMenuItem-root {
  @apply font-inter text-smena_text;
  height: 36px;
  font-size: 12px;
  line-height: 155%;
  letter-spacing: 0.4px;
}

/* Paper select */
.MuiPaper-root .MuiMenuItem-root.Mui-selected::after {
  content: '';
  background-image: none !important;
}

/* Autocomplete */
.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true'] {
  justify-content: space-between !important;
}

.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected='true']::after {
  content: '';
  background-image: url('../images/check.svg') !important;
  height: 24px;
  min-width: 24px;
}

.css-1uwzc1h-MuiSelect-select-MuiInputBase-input:focus {
  @apply rounded-lg !important;
}

.MuiInputBase-root,
.MuiAutocomplete-root {
  @apply text-sm rounded-lg !important;
  min-height: 32px !important;
}

.MuiAutocomplete-root {
  width: 100% !important;
}

.MuiAutocomplete-inputRoot {
  @apply text-sm font-inter text-smena_gray-80 !important;
  letter-spacing: 0.15px !important;
  padding: 0 32px 0 0 !important;
}

.MuiOutlinedInput-notchedOutline {
  @apply border border-smena_gray-30 !important;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:enabled:hover .MuiOutlinedInput-notchedOutline {
  @apply border-smena_gray-40 !important;
}

.css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  @apply border-primary !important;
}

.css-16awh2u-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  padding: 8px 18px 8px 12px !important;
}

.css-1q60rmi-MuiAutocomplete-endAdornment {
  top: calc(50% - 13px) !important;
}

.css-1glvl0p-MuiButtonBase-root-MuiIconButton-root-MuiAutocomplete-clearIndicator {
  padding: 1px !important;
}

.css-b7k0tb-MuiAutocomplete-listbox .MuiAutocomplete-option,
.MuiAutocomplete-input {
  @apply text-sm font-inter text-smena_gray-80 !important;
  letter-spacing: 0.15px !important;
}
.MuiAutocomplete-input::placeholder {
  @apply text-smena_gray-40 opacity-100 !important;
}

.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root,
.css-segi59 {
  @apply font-inter text-smena_text !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 143% !important;
  letter-spacing: 0.15px !important;
}

.MuiOutlinedInput-root.Mui-disabled,
.css-segi59.Mui-disabled {
  @apply bg-smena_gray-5;
}

/* Autocomplete */

.MuiInputBase-input {
  @apply text-smena_text;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.15px;
  box-shadow: none !important;
  min-height: 32px !important;
  margin: 0 !important;
  box-sizing: border-box !important;
}

.MuiInputBase-input.Mui-disabled {
  cursor: not-allowed;
}

.MuiSelect-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e") !important;
  background-position: right 0.5rem center !important;
  background-repeat: no-repeat !important;
  background-size: 1.5em 1.5em !important;
}

.MuiSelect-select:focus,
.MuiSelect-select:hover {
  background-image: url('../images/arrow.svg') !important;
}

select:enabled:hover,
select:focus {
  background-image: url('../images/arrow.svg');
}

.leads .shadow:first-child .tracking-wider {
  text-align: center;
}

/* Chip */
.css-9j66gs,
.css-r4c50g-MuiButtonBase-root-MuiChip-root {
  @apply text-smena_text !important;
  border-radius: 8px !important;
  height: 20px !important;
  background-color: rgba(0, 0, 0, 0.08) !important;
}
/* Chip */

.Mui-error .MuiOutlinedInput-notchedOutline {
  @apply border-smena_red-mark !important;
}

.Mui-error .MuiOutlinedInput-notchedOutline {
  @apply border-smena_red-mark !important;
}

.max-w-mac {
  max-width: 1920px;
}

/* .w-input {
  width: 300px;
}

.w-mini-input {
  width: 135px;
} */

.schedule,
.shifts {
  max-width: 1085px;
  margin-bottom: 20px;
}

/* .shifts {
  width: 1065px;
  margin-bottom: 20px;
} */

.schedule__wrapper,
.shifts__wrapper {
  @apply bg-smena_white;
  box-shadow: 2px -2px 4px rgba(189, 189, 189, 0.25),
  -2px 2px 4px rgba(189, 189, 189, 0.25);
  border-radius: 8px;
  padding: 20px 25px;
  width: 100%;
}

.schedule__calendar,
.shifts__calendar {
  overflow: auto;
  border-radius: 8px;
  min-height: 530px;
}

.schedule__wrapper .rbc-month-view,
.shifts__wrapper .rbc-month-view {
  @apply rounded-lg;
}

.rbc-calendar {
  @apply gap-y-5 w-[700px];
}

.rbc-toolbar-label {
  text-align: right;
}

.rbc-row-segment {
  @apply flex justify-end;
}

.rbc-off-range-bg {
  @apply bg-smena_gray-30 bg-opacity-40 !important;
  position: relative;
  z-index: 10;
}

.rbc-event {
  @apply w-[90px] mx-[5px] mb-[5px] !important;
}

.rbc-header {
  @apply font-inter text-smena_text-helper uppercase !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 157% !important;
  letter-spacing: 0.1px !important;
}

.rbc-date-cell {
  @apply text-left pl-2 font-inter !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 157% !important;
  letter-spacing: 0.1px !important;
}

.shifts .rbc-event {
  @apply pointer-events-none;
}
.shifts .rbc-day-bg {
  @apply cursor-pointer;
}

.rbc-event {
  padding: 0 !important;
  height: 17px !important;
}

.rbc-event:focus {
  outline: none !important;
}

.rbc-show-more {
  @apply font-roboto text-smena_text-secondary bg-transparent !important;
  font-weight: 500;
  font-size: 10px;
  line-height: 166%;
  letter-spacing: 0.4px;
}

.confirmed {
  @apply bg-smena_green-extra_light !important;
}

.wait_foreman_schedule {
  @apply bg-smena_yellow-extra_light !important;
}
.without_marks,
.wait_worker_schedule {
  @apply bg-smena_orange-extra_light !important;
}

.working_schedule,
.custom_schedule,
.working_shifts {
  @apply bg-primary-secondary !important;
}

.custom_schedule span {
  @apply text-smena_white !important;
}

.ineligible_schedule {
  @apply bg-smena_gray-5 !important;
}

.refused_schedule {
  @apply bg-smena_red-extra_light !important;
}
.intership_shift {
  @apply bg-smena_purple-light !important;
}

.other_facility {
  @apply opacity-40;
}

.add_custom_schedule {
  @apply bg-primary;
}

.rejected_shift {
  @apply bg-smena_red-extra_light !important;
}

.nonsuper_schedule {
  @apply bg-smena_bb-border_light !important;
}

.working_shifts span {
  @apply text-smena_white !important;
}

.import-error__row:nth-child(odd) {
  @apply bg-smena_gray-2;
}

.import-error__row:nth-child(even) {
  background-color: #fff;
}

.main-table {
  max-height: 520px;
  overflow-y: auto;
}

/* warning exclamation mark at toaster */

.Toastify__toast {
  @apply font-inter font-normal !important;
  font-style: normal;
  font-size: 14px;
  line-height: 143%;
  letter-spacing: 0.15px;
}

.Toastify__toast {
  align-items: center !important;
  padding: 0 22px !important;
}

.Toastify__close-button {
  align-self: auto !important;
}

.Toastify__toast-body {
  padding: 0 32px 0 0 !important;
}

.Toastify__toast--info .Toastify__close-button {
  opacity: 1 !important;
}

.Toastify__close-button > svg {
  width: 21px !important;
  height: 21px !important;
}

.Toastify__toast-container {
  width: auto !important;
  min-width: 100px;
}

.Toastify__toast-theme--colored.Toastify__toast--success {
  @apply bg-smena_green-dark !important;
}

.Toastify__toast-theme--colored.Toastify__toast--info {
  @apply bg-primary-dark !important;
}

/* Google Play Icon */

.googlePlay {
  enable-background: new 0 0 5435.8 1604;
}

.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
}

.st1 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #a6a6a6;
}

.st2 {
  fill: #ffffff;
}

.st3 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}

.st4 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: url(#SVGID_1_);
}

.st5 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: url(#SVGID_2_);
}

.st6 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: url(#SVGID_3_);
}

.st7 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: url(#SVGID_4_);
}

.st8 {
  opacity: 0.2;
  fill-rule: evenodd;
  clip-rule: evenodd;
  enable-background: new;
}

.st9 {
  opacity: 0.12;
  fill-rule: evenodd;
  clip-rule: evenodd;
  enable-background: new;
}

.st10 {
  opacity: 0.25;
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
  enable-background: new;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.codeInput {
  @apply w-full text-center;
}
.codeInput > input[type='number'] {
  @apply font-roboto text-center text-2xl text-smena_text-secondary
  p-0 mr-2 w-10 h-12 bg-smena_white
  border-1.5 border-smena_bb-border_light rounded-md;
}

/* Payments page */

.payments-row:not(:last-child) {
  margin-bottom: 10px;
}

.main-page-form {
  @apply flex flex-col bg-smena_white px-10 py-6 rounded-lg;
  width: 500px;
}

.side-menu {
  @apply h-screen overflow-y-auto transition-all pt-6 duration-300 bg-smena_white fixed top-0 left-0 z-30;
  filter: drop-shadow(2px -2px 4px rgba(189, 189, 189, 0.25));
}

.side-menu__content {
  @apply flex flex-col justify-between;
  height: calc(100vh - 30px);
}

.show-menu {
  @apply px-6 w-screen xs:w-[260px];
}

.hidden-menu {
  width: 56px;
}

.content {
  @apply flex flex-col min-h-screen relative;
}

.open_menu {
  @apply w-full md:w-[calc(100%_-_56px)] md:left-[56px];
}
.close_menu {
  @apply md:w-[calc(100%_-_260px)] md:left-[260px];
}

.subMenu {
  display: none;
}

.show-subMenu {
  display: block;
  margin-left: 20px;
}

.submenu-item::before,
.submenu-item::after,
.submenu-item:not(:last-child)::after,
.submenu-item:not(:first-child):not(:last-child)::after {
  @apply border-l border-b rounded-bl-lg border-smena_gray-30 block absolute;
  content: '';
  left: -1px;
  z-index: 1;
}

.submenu-item::before,
.submenu-item:not(:last-child)::after {
  top: 50%;
  transform: translate(0, -60%);
}

.submenu-item::before {
  width: 10px;
  height: 10px;
}

.submenu-item::after {
  width: 1px;
  height: 70%;
  top: 0;
  transform: translate(0, -40%);
}

.submenu-item:not(:last-child)::after {
  width: 1px;
  height: 100%;
}

.submenu-item:not(:first-child):not(:last-child)::after {
  width: 1px;
  height: 110%;
  top: 40%;
}

.timelines {
  width: fit-content;
}

.timelines .header {
  display: grid;
  grid-template-columns: 200px repeat(24, 45px);
}

.shiftsTime {
  display: grid;
  height: 40px;
  grid-template-columns: 200px repeat(24, 45px);
}

.shiftsScheduleSidebar {
  @apply fixed overflow-x-auto top-0 h-full transition-all duration-500 ease-in-out pt-6 px-5 z-40 bg-smena_white;
  width: 430px;
}

.tooltip {
  @apply z-20;
  min-width: max-content;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.2))
  drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.32));
  filter: drop-shadow(0px 6px 15px rgba(0, 0, 0, 0.2))
  drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.32));
}

.tooltip > span {
  flex: 1 0 auto;
}

.tooltip_bottom-left:before {
  content: '';
  border: solid transparent;
  position: absolute;
  left: 10%;
  top: -5px;
  border-bottom-color: #f5f8ff; /* hard color */
  border-width: 5px;
  margin-top: -5px;
}

.tooltip_top-center .tooltip_top-left:before {
  content: '';
  border: solid transparent;
  position: absolute;
  left: 10%;
  bottom: -5px;
  border-top-color: inherit; /* hard color */
  border-width: 5px;
  margin-bottom: -5px;
}

.tooltip_top-center:before {
  content: '';
  border: solid transparent;
  position: absolute;
  left: 50%;
  bottom: -5px;
  border-top-color: #f5f8ff; /* hard color */
  border-width: 5px;
  margin-bottom: -5px;
}

.tooltip_top-center:before {
  content: '';
  border: solid transparent;
  position: absolute;
  left: 50%;
  bottom: -5px;
  border-top-color: #f5f8ff; /* hard color */
  border-width: 5px;
  margin-bottom: -5px;
}

.tooltip_usual:before {
  content: '';
  position: absolute;
  right: 100%;
  top: 50%;
  border: solid transparent;
  border-right-color: inherit; /* hard color */
  border-width: 5px;
  margin-top: -5px;
}

.name_shadow {
  box-shadow: 2px 2px 4px rgba(189, 189, 189, 0.25);
}

.stats_shadow {
  box-shadow: -2px 2px 4px rgba(189, 189, 189, 0.25);
}

.menu__element {
  position: relative;
}

.menu__element .menu__link {
  padding-bottom: 10px;
}

.menu__element:not(:last-child) {
  margin-right: 50px;
}

.menu__link {
  @apply disabled:text-smena_text-disabled disabled:cursor-not-allowed;
}

.menu__element-active:after {
  @apply bg-primary;
  content: '';
  display: inline-block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 5px;
  border-radius: 15px 15px 0 0;
}

.filter-bar {
  @apply rounded-lg bg-smena_white mb-5;
  padding: 15px 25px;
  box-shadow: 2px -2px 4px rgba(189, 189, 189, 0.25),
  -2px 2px 4px rgba(189, 189, 189, 0.25);
}

.filter__popup {
  @apply absolute top-full left-0 p-5 mt-2 flex flex-col
  bg-smena_white rounded-lg shadow-smena z-50;
  width: 305px;
}

/* Pagination */
.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected,
.css-18lqwt2.Mui-selected {
  @apply rounded-full bg-primary-secondary text-smena_white !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.css-10w330c-MuiButtonBase-root-MuiPaginationItem-root,
.css-18lqwt2 {
  @apply text-smena_text rounded-full !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

/* Pagination */

.inputHeight {
  min-height: 82px;
  height: auto;
}

.paddingForSelects {
  padding-bottom: 28px;
}

.crudUserInput {
  width: 180px;
}

.w-200px {
  width: 200px;
}

.crudPositionInput {
  width: 250px;
}

.periodInput {
  width: 193px;
}

.input__small {
  width: 150px;
}

.input__period {
  width: 239px;
}

.input__status {
  width: 177px;
}

.vacancyInput {
  width: 190px;
}

.vacancyCasing {
  width: 130px;
}

.vacancyInput110 {
  width: 110px;
}

.vacancyInput180 {
  width: 180px;
}

.vacancyInputLong {
  width: 220px;
}

.vacancyInput260 {
  width: 260px;
}

.input230 {
  width: 230px;
}

.input140 {
  width: 140px;
}

.crudUserMulty {
  width: 220px;
}

.crudUserCitizen {
  width: 278px;
}

.grayAcross {
  color: rgba(27, 31, 59, 0.4);
}

.groupImage {
  max-width: 74px;
  width: 74px;
  height: 74px;
}

.groupContent {
  max-width: 180px;
}

.uploadButton {
  border-width: 3px;
  width: 190px;
  height: 190px;
}

.table__header {
  @apply flex items-center Table-H1 uppercase text-smena_text-secondary py-4 text-left;
}

.table__content {
  @apply py-4 font-roboto text-smena_text;
  font-size: 13px;
  font-weight: normal;
  line-height: 143%;
  letter-spacing: 0.15px;
}
.add_profession {
  max-width: max-content;
}
.add_profession__table-edit {
  grid-template-columns:
    minmax(250px, 255px) minmax(140px, 150px) minmax(180px, 185px)
    minmax(115px, 120px) minmax(80px, 85px) minmax(115px, 120px)
    minmax(85px, 90px) minmax(130px, 130px) minmax(110px, 115px)
    110px;
}
.add_profession__table-view {
  grid-template-columns:
    minmax(250px, 255px) minmax(140px, 150px) minmax(180px, 185px)
    minmax(115px, 120px) minmax(80px, 85px) minmax(115px, 120px)
    minmax(85px, 90px) minmax(130px, 130px) minmax(120px, 120px);
}

.add_profession__name {
  max-width: 220px;
}

.add_profession__paymentType {
  max-width: 110px;
}

.add_profession__rate {
  width: 80px;
}

.breadcrumbs {
  @apply md:mb-[10px];
}

.breadcrumbs .breadcrumb {
  margin-right: 5px;
}

.breadcrumbs .breadcrumb:not(:last-child)::after {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background-image: url(../images/breadcrumbArrow.svg);
  margin-left: 5px;
}

/* Moderation page */

.moderation__status {
  @apply flex justify-center items-center;
  width: 90px;
  height: 23px;
  border-radius: 54px;
}

.minus[type='checkbox']:checked {
  @apply bg-primary-secondary;
  background-image: url('../images/minus.svg') !important;
  background-size: 50% !important;
}

/* Period page */
.period {
  @apply bg-smena_white rounded-lg;
}

.shiftModerationStatus {
  @apply flex items-center rounded gap-x-2;
  padding: 2px 8px;
}

.employer__mark {
  @apply absolute w-0 h-0 z-10 transform rotate-45 translate-x-1/2;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid #393b55;
}

.hoursCount {
  @apply font-inter;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 143%;
  letter-spacing: 0.15px;
}

.red-border {
  @apply border-smena_red-mark;
}
.yellow-border {
  @apply border-smena_yellow-mark;
}
.green-border {
  @apply border-smena_green-mark;
}
.gray-border {
  @apply border-smena_gray-40;
}
.purple-border {
  @apply border-smena_purple;
}
.radio-block {
  @apply col-span-4 p-3 flex items-center rounded-lg border gap-x-3;
}

[type='radio'] {
  @apply bg-smena_gray-5 border border-transparent;
  box-shadow: none;
}

[type='radio']:focus {
  box-shadow: none;
}

[type='radio']:checked,
[type='radio']:checked:hover,
[type='radio']:checked:focus {
  @apply bg-smena_green-mark border border-smena_green-mark;
  box-shadow: none;
}

.moderation__header {
  @apply sticky top-0 z-20 flex items-center border-b border-smena_gray-30;
  height: 40px;
}

.row-days__days-num {
  @apply grid overflow-x-auto bg-smena_white;
  scrollbar-width: none;
}

.row-days__days-num::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  scrollbar-width: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.period__header {
  @apply Caption text-smena_text;
}

.period__fio {
  @apply Caption;
  margin-right: 15px;
}

.table__stats {
  @apply grid justify-items-end text-smena_text-secondary;
  font-size: 10px;
  line-height: 166%;
  letter-spacing: 0.4px;
  grid-template-columns: repeat(5, 55px) 60px 55px;
  column-gap: 5px;
}

.period__event {
  @apply inline-grid border-0.5 rounded border-smena_gray-50 w-full h-full;
}

.period__text {
  @apply flex justify-center items-center cursor-pointer;
  width: 35px;
  height: 20px;
}

.sidebar__shift {
  @apply rounded-lg grid;
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.13);
  padding: 15px;
  column-gap: 10px;
}

.search__user {
  @apply w-full flex flex-col justify-center items-center gap-y-4;
  height: calc(100% - 100px);
}

/* antd */

.custom__picker {
  @apply font-inter text-smena_text rounded-lg text-[14px] leading-[143%] tracking-[0.15px] h-[32px]
}
.ant-picker-focused{
  @apply border-primary shadow-none
}

.ant-picker-clear {
  @apply flex justify-center items-center w-[18px] h-[18px];
}
.custom__picker input{
  @apply Body2 text-smena_text
}
.custom__picker input::placeholder {
  @apply text-smena_text-helper;
}

.overlay {
  @apply absolute top-0 left-0 z-50 w-full h-full bg-smena_gray-90 opacity-20;
}

.add_employer {
  @apply rounded-lg absolute overflow-hidden bg-smena_white transform -translate-y-1/4;
  z-index: 51;
  height: 450px;
}

.ozon-moderation__download-example-block {
  @apply grid;
  grid-template-columns: 250px 65px;
}

.ozon__import-error {
  @apply bg-smena_white p-5 rounded-lg flex flex-col w-[575px] h-auto shadow-smena;
}

.ozon__import-error_table {
  @apply grid gap-x-5;
  height: 56px;
  grid-template-columns: repeat(2, 190px) 80px;
}

.ozon__import-error_table_block {
  @apply overflow-y-auto max-h-[600px];
}

.switch_btn {
  @apply relative inline-block w-8 h-4;
}

/* Hide default HTML checkbox */
.switch_btn input {
  @apply opacity-0 w-0 h-0;
}

/* The slider */
.switch_btn .slider {
  @apply absolute cursor-pointer bg-smena_gray-50 rounded-xl;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
}

.switch_btn .slider:before {
  @apply absolute h-3 w-3 bg-smena_white rounded-full;
  content: '';
  left: 2px;
  bottom: 2px;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.switch_btn input:checked + .slider {
  @apply bg-primary;
}

/* .switch_btn input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
} */

.switch_btn input:checked + .slider:before {
  transform: translateX(16px);
}

.documents .data-block {
  width: 420px;
}

.documents .photo-block {
  width: 370px;
}

.documents .approve-block {
  width: 220px;
}

.documents .card-block {
  width: 290px;
}

.documents .card-block .cardInputData {
  @apply rounded-md bg-smena_bb-background_base font-inter;
  font-weight: 400;
  font-size: 13px;
  line-height: 143%;
  letter-spacing: 0.15px;
}

.documents .card-block .cardInputData::placeholder {
  @apply text-smena_bb-border;
}

.documents .bank-block {
  width: 520px;
}

.documents .approve-block .approve-radio {
  @apply rounded-lg border flex items-center gap-3;
  padding: 3.5px 12px;
}

.documents .save-block {
  max-width: 580px;
}

.documents .upload_block {
  width: 150px;
}
.documents .upload_button {
  @apply border w-full border-dashed rounded-md border-smena_bb-border
  overflow-hidden flex justify-center items-center;
  height: 150px;
}

.documents .main-pages {
  max-width: 1070px;
}

.documents .main-pages .seria {
  width: 80px;
}

.documents .main-pages .number {
  width: 130px;
}

.documents .main-pages .get-date {
  width: 150px;
}

.error-message {
  @apply text-smena_red inline-flex pt-1 max-h-10;
}

.approve_radio:checked,
.approve_radio:checked:hover,
.approve_radio:checked:focus {
  background: white;
  position: relative;
}

.approve_reject:checked,
.approve_reject:checked:hover,
.approve_reject:checked:focus {
  @apply border-smena_red;
}

.approve_penalty:checked,
.approve_penalty:checked:hover,
.approve_penalty:checked:focus {
  @apply border-primary;
}

.approve_reject:checked::after,
.approve_approve:checked::after,
.approve_penalty:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
}
.approve_reject:checked::after {
  @apply bg-smena_red-mark;
}
.approve_approve:checked::after {
  @apply bg-smena_green-mark;
}
.approve_penalty:checked::after {
  @apply bg-primary;
}
.userMainInfo {
  max-width: max-content;
}

.chart-filter {
  width: 160px;
}
.chartsBlock {
  @apply grid gap-5 grid-cols-[minmax(auto,_635px)] justify-between lg:grid-cols-[minmax(450px,_635px)_minmax(500px,_635px)];
}

.trendsBlock {
  @apply flex flex-col gap-x-5 gap-y-10 shadow-smena rounded-lg bg-smena_white;
}

.chartBlock {
  max-width: 595px;
  height: 240px;
}

.chartTrendBlock {
  max-width: 600px;
  height: 240px;
}

/* loader */
.loader-ring {
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
}
.loader-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  margin: 2px;
  border: 2px solid #397dff;
  border-radius: 50%;
  animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #397dff transparent transparent transparent;
}
.loader-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.loader-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.loader-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loader-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.recharts-cartesian-axis-tick-value {
  @apply font-roboto text-smena_text;
  font-weight: 400;
  font-size: 13px;
  line-height: 143%;
  letter-spacing: 0.15px;
}

.recharts-default-tooltip {
  @apply border-0.5 border-smena_gray-5 rounded;
  box-shadow: 2px -2px 4px rgba(189, 189, 189, 0.25),
  -2px 2px 4px rgba(189, 189, 189, 0.25);
}
.recharts-tooltip-label {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 143%;
  letter-spacing: 0.15px;
}
.recharts-tooltip-item {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 143%;
  letter-spacing: 0.15px;
}
.recharts-tooltip-item-value {
  margin-left: 4px;
}

.groupName {
  @apply overflow-hidden overflow-ellipsis text-smena_text;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.requestGraphic {
  display: grid;
  grid-template-columns: 200px minmax(0, max-content);
}

.requestScheduleProfessions {
  overflow-y: auto;
  max-height: 500px;
}

.requestScheduleDates {
  overflow: auto;
  max-height: 520px;
}

.requestScheduleProfessionsRows {
  padding: 8px 0;
  display: grid;
  grid-template-rows: 32px;
  grid-auto-flow: column;
}

.requestRows {
  padding: 11px 0;
  display: grid;
  grid-template-rows: 20px;
  grid-auto-flow: column;
}

.graphigRows {
  padding: 4px 0;
  display: grid;
  grid-template-rows: 20px;
  grid-auto-flow: column;
}

.graphigRowsData {
  padding: 4px 0;
  display: grid;
}

.requestGraphicCellDates {
  @apply bg-smena_bb-background rounded;
}

.graphicSerchUserHeader {
  display: grid;
  grid-template-columns: 230px 80px;
}
.graphicSearchUserTable {
  display: grid;
  grid-template-columns: 200px 115px;
}

.blockTemplate {
  @apply p-6 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-5;
}

.blockTitleTemplate {
  @apply text-smena_text-secondary col-span-full;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 175%;
  letter-spacing: 0.15px;
}

.groupPageBlock {
  @apply 2xl:col-span-6 2xl:col-start-1 xl:col-span-8 lg:col-span-10 col-span-full;
}

.userPageBlock {
  @apply 2xl:col-span-7 2xl:col-start-1 xl:col-span-8 lg:col-span-10 col-span-full;
}

.facilityPageBlock {
  @apply 2xl:col-span-8 2xl:col-start-1 xl:col-span-9 md:col-span-10 col-span-full;
}

.vacancyPageBlock {
  @apply 2xl:col-span-6 2xl:col-start-1 xl:col-span-8 lg:col-span-10 col-span-full;
}

.requestPageBlock {
  @apply xl:col-span-9 lg:col-span-10 md:col-span-11 col-span-full;
}
.rateBlockAuto {
  display: grid;
  grid-template-columns: minmax(max-content, 150px) 135px;
}

.independentBlock {
  @apply col-span-full p-6 border rounded-lg shadow-smena bg-smena_white flex flex-col gap-y-5;
}

.requestRow:hover {
  @apply bg-smena_bb-background_base;
}

.rate-block {
  @apply grid justify-between grid-cols-[repeat(1,_max-content)] gap-y-5 md:grid-cols-[repeat(2,_max-content)];
}

.scheduleRequestsTable {
  max-height: 355px;
  overflow-x: hidden;
  overflow-y: auto;
}

.scheduleHasWorkers {
  grid-template-columns: min-content 1fr;
}
